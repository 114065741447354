import styled from '@emotion/styled';
import { colors, colorFunctions } from '../styles/colors';
import { fonts, responsiveFont } from '../styles/fonts';
import {
  commonElementSpacing,
  resetElementSpacing,
  spacings,
} from '../styles/layouts';

export const P = styled('p')`
  ${commonElementSpacing}

  *:last-child {
    margin-bottom: 0;
  }
`;

export const H1 = styled('h1')`
  ${resetElementSpacing}
  ${fonts.base}
  ${fonts.display}
  ${fonts.bold}
  ${responsiveFont(
    1.75,
    2
  )}
  color: ${colors.primaryDark};
`;

export const H2 = styled('h2')`
  ${resetElementSpacing}
  ${fonts.base}
  ${fonts.boldItalic}
  ${responsiveFont(
    1.25,
    1.5
  )}
  color: ${colorFunctions.textPrimary};
`;

export const H3 = styled('h3')`
  ${resetElementSpacing}
  ${fonts.base}
  ${fonts.bold}
  ${responsiveFont(
    1,
    1.25
  )}
  color: ${colorFunctions.textPrimary};
`;

export const H4 = styled('h4')`
  ${resetElementSpacing}
  ${fonts.base}
  ${fonts.display}
  ${fonts.semiBold}
  ${responsiveFont(
    1.25,
    1.5
  )}
`;

export const H5 = styled('h5')`
  ${resetElementSpacing}
  ${fonts.base}
  ${responsiveFont(0.75, 1)}
`;

export const H6 = styled('h6')`
  ${resetElementSpacing}
  ${fonts.base}
`;

export const StyledDate = styled('span')`
  ${fonts.base}
  ${fonts.bold}
  ${responsiveFont(
    0.7,
    0.875
  )}
  color: ${colorFunctions.textLight};
`;

export const ErrorText = styled(P)`
  ${fonts.error}
`;

export const BlockQuote = styled('blockquote')`
  ${fonts.base}
  ${fonts.semiBoldItalic}
  border-left: 3px solid ${colors.primary};
  color: ${colorFunctions.textLight};
  margin: 0 ${spacings.section} ${spacings.section} ${spacings.section};
  padding: 0 0 0 1.5rem;

  *:last-child {
    margin-bottom: 0;
  }
`;

export const Tag = styled('span')`
  ${fonts.display}
  ${fonts.bold}
  ${responsiveFont(0.7, 0.875)}
  color: ${colors.primaryDark};
  font-variant: small-caps;
  letter-spacing: .1em;
  text-transform: lowercase;
`;

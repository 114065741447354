import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { colors } from '../styles/colors';
import { fonts, responsiveFont } from '../styles/fonts';
import { layoutContainer, spacings } from '../styles/layouts';
import { linkStyles } from '../components/link';

const HeaderContainer = styled('header')`
  margin: ${spacings.doubleUnit} 0;
  padding: ${spacings.doubleUnit} 0;
`;

const HeaderContentContainer = styled('div')`
  ${layoutContainer}
`;

const HeaderLink = styled(Link)`
  ${linkStyles}
  ${fonts.base}
  ${fonts.title}
  ${responsiveFont(1.75, 2)}
  color: ${colors.primaryDark};
`;

const Header = () => (
  <HeaderContainer>
    <HeaderContentContainer>
      <HeaderLink to="/">Erica Kangas</HeaderLink>
    </HeaderContentContainer>
  </HeaderContainer>
);

export default Header;
